import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-light light-blue lighten-4 d-sm-none">
          <a className="navbar-brand" href="/">
            <img
              src="/uploads/voorlichtingsles-logo.png"
              height="40"
              alt="Voorlichtingsles Volwassen Worden"
            />
          </a>
          <button
            className="navbar-toggler toggler-example"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent1"
            aria-controls="navbarSupportedContent1"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="dark-blue-text">
              <i className="fas fa-bars fa-1x" />
            </span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent1"
          >
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/lessen"
                >
                  Lessen
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/lesmaterialen"
                >
                  Lesmaterialen
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/multimedia"
                >
                  Multimedia aanpak
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/lesmaterialen/lespakket-gratis-bestellen"
                >
                  Lespakket gratis bestellen
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/service"
                >
                  Service
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <header className="bg-blue d-none d-sm-block pt-3">
          <div
            className="container-fluid"
            style={{
              "border-bottom": "rgb(238,38,110) 7px solid"
            }}
          >
            <div className="container">
              <div className="row hideMegaMenu">
                <div className="col-12">
                  <img
                    src="/uploads/voorlichtingsles-logo.png"
                    alt="Voorlichtingsles Volwassen Worden"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <a className="show-menu" href="/" data-value="-1">
                    <i className="fas fa-home fa-2x" />
                  </a>
                  <a className="show-menu" href="/lessen" data-value="0">
                    Lessen
                  </a>
                  <a
                    className="show-menu"
                    href="/lesmaterialen"
                    data-value="1"
                  >
                    Lesmaterialen
                  </a>
                  <a
                    className="show-menu"
                    href="/multimedia"
                    data-value="2"
                  >
                    Multimedia aanpak
                  </a>
                  <a
                    className="show-menu"
                    href="/lesmaterialen/lespakket-gratis-bestellen"
                    data-value="3"
                  >
                    Lespakket gratis bestellen
                  </a>
                  <a className="show-menu" href="/service" data-value="4">
                    Service
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="megamenu container-fluid"
            data-show="0"
            style={{
              "z-index": "99999",
              position: "absolute",
              display: "none"
            }}
          >
            <div id="megamenu_0" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_1_richtlijnen.jpg"
                      className="img-fluid"
                      alt="Richtlijnen"
                    />
                    <a
                      href="/lessen/richtlijnen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Richtlijnen voor de seksuele voorlichting
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_1_lesvoorbereiding.jpg"
                      className="img-fluid"
                      alt="Lesvoorbereiding"
                    />
                    <a
                      href="/lessen/lesvoorbereiding"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Lesvoorbereiding
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_1_tips.jpg"
                      className="img-fluid"
                      alt="Tips"
                    />
                    <a
                      href="/lessen/tips"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Tips voor de seksuele voorlichtingslessen
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_1" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-4">
                    <ul className="nav navigatie">
                      <li className="font-weight-bold">
                        <a
                          href="/lesmaterialen/themagebieden"
                          style={{
                            color: "inherit"
                          }}
                        >
                          Themagebieden
                        </a>
                        <div>
                          <a
                            href="/lesmaterialen/themagebieden/ontwikkeling-en-puberteit"
                            className="d-block mt-1 tab-menu"
                          >
                            Ontwikkeling en puberteit
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/geslachtsorganen"
                            className="d-block mt-1 tab-menu"
                          >
                            Geslachtsorganen
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/cyclus-en-menstruatie"
                            className="d-block mt-1 tab-menu"
                          >
                            Menstruatiecyclus
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/menstruatie-hygine"
                            className="d-block mt-1 tab-menu"
                          >
                            Menstruatie hygiëne
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/tampongebruik"
                            className="d-block mt-1 tab-menu"
                          >
                            Tampongebruik
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul className="nav navigatie">
                      <li className="font-weight-bold">
                        <a
                          href="/lesmaterialen/speciale-pakketten"
                          style={{
                            color: "inherit"
                          }}
                        >
                          Speciale pakketten
                        </a>
                        <div>
                          <a
                            href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-meisjes"
                            className="d-block mt-1 tab-menu"
                          >
                            Lesmateriaal voor meisjes
                          </a>
                          <a
                            href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens"
                            className="d-block mt-1 tab-menu"
                          >
                            Lesmateriaal voor jongens
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul className="nav navigatie">
                      <li className="font-weight-bold">
                        <a
                          href="/lesmaterialen/speciale-pakketten"
                          style={{
                            color: "inherit"
                          }}
                        >
                          Lespakket gratis bestellen
                        </a>
                        <div>
                          <a
                            href="/lesmaterialen/lespakket-gratis-bestellen/docentenhandleiding"
                            className="d-block mt-1 tab-menu"
                          >
                            Docentenhandleiding
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_2" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_3_instructiefilm.jpg"
                      className="img-fluid"
                      alt="Instructiefilm"
                    />
                    <a
                      href="/multimedia/instructiefilm-over-seksuele-voorlichting-volwassen-worden"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Instructiefilm over seksuele voorlichting «Volwassen
                      worden»
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_3_kennistest.jpg"
                      className="img-fluid"
                      alt="Online kennistest"
                    />
                    <a
                      href="/multimedia/online-kennistests"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Online kennistest
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_3_oefeningen.jpg"
                      className="img-fluid"
                      alt="Oefenbladen"
                    />
                    <a
                      href="/multimedia/oefenbladen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Oefenbladen
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_3" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_4_primaironderwijs.jpg"
                      className="img-fluid"
                      alt="Bestel nu gratis"
                    />
                    <a
                      href="/lesmaterialen/lespakket-gratis-bestellen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Bestel nu gratis
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_4" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_5_belangrijke_vragen.jpg"
                      className="img-fluid"
                      alt="Belangrijke vragen"
                    />
                    <a
                      href="/service/veel-gestelde-vragen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Belangrijke vragen over menstruatie en menstruatiehygiëne
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_5_belangrijke_links.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                    <a
                      href="/service/belangrijke-links"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Belangrijke links
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_5_contact.jpg"
                      className="img-fluid"
                      alt="Contact"
                    />
                    <a
                      href="/service/contact"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
          </div>
        </header>
        <main className="hideMegaMenu">
          <div className="container-fluid opening mb-3">
            <div className="container ">
              <div className="row">
                <div className="col-md-6 px-4 py-3">
                  <h1 className="h1-responsive text-white">
                    Docentenhandleiding
                  </h1>
                </div>
                <div className="col-md-6">
                  <img
                    src="/uploads/img_4458_5_bestellen.jpg"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid hideMegaMenu">
            <div className="container">
              <div id="breadcrumb">
                <div className="bc my-3 d-none d-sm-block ">
                  <a href="/">Home</a> &gt;{" "}
                  <a href="/lesmaterialen">
                    Lesmaterialen voor seksuele voorlichtingslessen
                  </a>{" "}
                  &gt;{" "}
                  <a href="/lesmaterialen/lespakket-gratis-bestellen">
                    Lespakket gratis bestellen
                  </a>{" "}
                  &gt;{" "}
                  <a href="/lesmaterialen/lespakket-gratis-bestellen/docentenhandleiding">
                    Docentenhandleiding
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div id="content">
                    <h1 className="h1-responsive my-3  ">
                      Docentenhandleiding
                    </h1>
                    <p className="lead hide" />
                    <p />
                    <p>
                      In de docentenhandleiding staan suggesties voor lessen
                      over de puberteit en het gebruik van het leerlingenboekje,
                      de film,&nbsp;het speciale boekje voor meisjes, het
                      bekkenbodemprofiel, het speciale boekje voor
                      jongens&nbsp;en de site. Ook geeft de handleiding extra
                      informatie en antwoorden op de vragen en opdrachten van de
                      lesmaterialen.
                    </p>
                    <div className="container my-3 bg-white" style={{"border-bottom": "solid 7px rgb(25, 154, 221)"}}>
                      <section className="dark-grey-text">
                        <div className="row pr-lg-5">
                          <div className="col-md-7 mb-4">
                            <div className="view">
                              <a
                                href="/uploads/blb_4644_docentenhandleiding-voorlichtingsles.pdf"
                                className="stretched-link"
                              >
                                <img
                                  className="img-fluid mt-3"
                                  src="/uploads/blb_4643_cover-handleiding.png"
                                  alt="Download lesmaterialen"
                                />
                              </a>{" "}
                            </div>
                          </div>
                          <div className="col-md-5 d-flex align-items-center">
                            <div>
                              <h3 className="font-weight-bold mb-4">
                                Docentenhandleiding
                              </h3>
                              <p>
                                Heldere instructie, lessuggesties, kopieerbladen
                                en uitleg van alle beschikbare materialen.
                              </p>
                              <a
                                href="/uploads/blb_4644_docentenhandleiding-voorlichtingsles.pdf"
                                type="button"
                                className="btn text-white bg-dark-blue waves-effect waves-light"
                              >
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <p />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="bg-blue zij-menu p-3">
                    <a href="/lesmaterialen" className="level-1 d-block ">
                      Lesmaterialen voor seksuele voorlichtingslessen
                    </a>
                    <a
                      href="/lesmaterialen/themagebieden"
                      className="level-2 d-block "
                    >
                      Themagebieden
                    </a>
                    <a
                      href="/lesmaterialen/themagebieden/ontwikkeling-en-puberteit"
                      className="level-3 d-block "
                    >
                      Ontwikkeling en puberteit
                    </a>
                    <a
                      href="/lesmaterialen/themagebieden/geslachtsorganen"
                      className="level-3 d-block "
                    >
                      Geslachtsorganen
                    </a>
                    <a
                      href="/lesmaterialen/themagebieden/cyclus-en-menstruatie"
                      className="level-3 d-block "
                    >
                      Cyclus en menstruatie
                    </a>
                    <a
                      href="/lesmaterialen/themagebieden/menstruatie-hygine"
                      className="level-3 d-block "
                    >
                      Menstruatie hygiëne
                    </a>
                    <a
                      href="/lesmaterialen/themagebieden/tampongebruik"
                      className="level-3 d-block "
                    >
                      Tampongebruik
                    </a>
                    <a
                      href="/lesmaterialen/speciale-pakketten"
                      className="level-2 d-block "
                    >
                      Speciale pakketten
                    </a>
                    <a
                      href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-meisjes"
                      className="level-3 d-block "
                    >
                      Lesmateriaal voor meisjes
                    </a>
                    <a
                      href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens"
                      className="level-3 d-block "
                    >
                      Lesmateriaal voor jongens
                    </a>
                    <a
                      href="/lesmaterialen/lespakket-gratis-bestellen"
                      className="level-2 d-block "
                    >
                      Lespakket gratis bestellen
                    </a>
                    <a
                      href="/lesmaterialen/lespakket-gratis-bestellen/docentenhandleiding"
                      className="level-3 d-block text-rood"
                    >
                      Docentenhandleiding
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div className="container pt-3 hideMegaMenu">
            <div className="text-center">
              <img
                className="pb-5"
                style={{
                  width: "290px"
                }}
                src="/uploads/ob-logo.png"
                alt="o.b. professioneel forum"
              />
            </div>
            <div className="row">
              <div className="col-md-4">
                <a className="main" href="/">
                  Homepage
                </a>
                <a href="/service/sitemap">Sitemap</a>
                <a href="/service/contact">Contact</a>
                <a href="/service/juridische-kennisgeving">
                  Juridische kennisgeving
                </a>
                <a href="/service/privacybeleid">Privacy statement</a>
                <a href="/service/cookies">Cookie statement</a>
              </div>
              <div className="col-md-4">
                <a
                  className="main"
                  href="/lesmaterialen/lespakket-gratis-bestellen"
                >
                  Lespakket gratis bestellen
                </a>
                <a href="/lesmaterialen/lespakket-gratis-bestellen">
                  Bestel het nu
                </a>                  
              </div>
              <div className="col-md-4">
                <a className="main" href="/multimedia">
                  Multimedia voor seksuele voorlichting
                </a>
                <a href="/multimedia/instructiefilm-over-seksuele-voorlichting-volwassen-worden">
                  Educatieve film "Opgroeien"
                </a>
                <a href="/multimedia/online-kennistests/quiz-puberteit">
                  Quiz puberteit
                </a>
                <a href="/multimedia/online-kennistests/quiz-menstruatie">
                  Quiz menstruatie
                </a>
                <a href="/multimedia/online-kennistests/quiz-menstruatie-hygiene">
                  Quiz menstruatiehygiëne
                </a>
                <a href="/multimedia/oefenbladen">Oefenbladen</a>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-4">
                <a className="main" href="/lesmaterialen">
                  Lesmaterialen voor seksuele voorlichtingslessen
                </a>
                <a href="/lesmaterialen/themagebieden">Themagebieden</a>
                <a href="/lesmaterialen/speciale-pakketten">
                  Speciale pakketten
                </a>
              </div>
              <div className="col-md-4">
                <a className="main" href="/lessen">
                  Lesgeven
                </a>
                <a href="/lessen/richtlijnen">
                  Richtlijnen voor seksuele voorlichting
                </a>
                <a href="/lessen/lesvoorbereiding">Lesvoorbereiding</a>
                <a href="/lessen/tips">Tips voor seksuele voorlichting</a>
              </div>
              <div className="col-md-4">
                <a className="main" href="/service">
                  Service en veelgestelde vragen
                </a>
                <a href="/service/veel-gestelde-vragen">
                  Veelgestelde vragen
                </a>
                <a href="/service/belangrijke-links">Belangrijke links</a>
                <a href="/service/contact">Contact &amp; feedback</a>
              </div>
            </div>
            <div className="row mt-5 pb-3">
              <div className="col-12 font-smaller">
                <p>© Johnson &amp; Johnson Consumer B.V. 2006 - 2019.</p>
                <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie-instellingen</button>
                <p style={{"float": "left"}}>
                  Deze website is gepubliceerd door Johnson &amp; Johnson                               
                  Consumer B.V., die als enige verantwoordelijk is voor de
                  inhoud. De site is bedoeld voor een Nederlands publiek.
                  <br />
                  Voor het laatst bijgewerkt: 14 oktober 2019.
                </p>
              </div>
            </div>
          </div>
        </footer>
        <script type="text/javascript" src="/js/jquery-3.5.1.js" />
        <script type="text/javascript" src="/js/popper.min.js" />
<script type="text/javascript" src="/js/jquery-migrate-3.3.0.js" />
        <script type="text/javascript" src="/js/bootstrap.min.js" />
        <script type="text/javascript" src="/js/mdb.min.js" />
        <div className="hiddendiv common" />
        <script type="text/javascript" src="/system/js/user.js" />
      </div>
    );
  }
}

export default Page;
